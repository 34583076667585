import React, { Component } from "react";
import axios from "axios";
import '../../App.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


const now = new Date();
function checkZero(num) {
    return (num + "").length === 1 ? "0" + num : num;
}
export default class CalendarView extends Component {
  
     state={number: '', id : '', reason: '', titles:[], timeTables: [], dates: [], clusterCompanys:[], consultants: [], keys: [], consultantId: 0, startDate: now.getFullYear() + '-' + checkZero(now.getMonth() + 1) + '-' + checkZero(now.getDate()), companyId: sessionStorage.getItem('companyId'), entry: '', review: ''}
    calendar()
    {    
        this.setState({ consultants: [], consultantId: 0})
     document.getElementById('calendarViewAction').style = 'display:block'
     this.props.setLoader(true)
     axios.get(localStorage.getItem('host') + '/auth/getclustercompanywithconsultants/' + sessionStorage.getItem('companyId'), {
        headers: {
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        }
    }).then(
        res => {
            this.setState({ clusterCompanys: res.data });
            this.getTimeTableList()
            this.setConsultantList(this.state.companyId)
        }, err => {
            this.setState({ error: err + "" })
            this.props.setLoader(false)
        }
    )
    }  
    getTimeTableList() {
        this.setState({ list: [], msg: '' });
        this.props.setLoader(true)
        axios.get(localStorage.getItem('host') + '/patient/getcalendar/'+this.state.companyId+'/'+this.state.startDate+"/"+this.state.consultantId,  {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ titles: res.data.titles, dates: res.data.dates, timeTables: res.data.timeTables, keys: res.data.keys });
                this.props.setLoader(false)
            }, err => {
                this.setState({ error: err + "" })
                this.props.setLoader(false)
            }
        )
    }
    closePopup() {
        document.getElementById('calendarViewAction').style = 'display:none'
    }
    cancelAppointment(entry, appointmentId, review) {
        console.log("...... cancel entry")
        this.setState({entry: entry.replace("<br />", ''), id: appointmentId, review: review})
        document.getElementById('cancelAppointmentAction').style = 'display:block' 
    }
    sendCancelAppointment() {
        this.props.setLoader(true)
        var type = this.state.entry.includes("REVIEW")?"REVIEW":"APPOINTMENT"
        axios.get(localStorage.getItem('host') + '/patient/cancelappointment/' + this.state.id+"/"+type+"/"+this.state.review+'?reason='+this.state.reason, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(
            res => {
                this.setState({ msg: res.data });
                this.props.setLoader(true)
                this.closeCancelPopup()
                this.getTimeTableList()
            }, err => {
                console.log(".....")
                 this.setState({error: err+""})
                this.props.setLoader(false)
            }
        )
    }
    closeCancelPopup() {
        document.getElementById('cancelAppointmentAction').style = 'display:none'
    } 
    processDate(date)
      {
        const now = new Date(date);
        const todayY = now.getFullYear();
        const todayM = checkZero(now.getMonth() + 1);
        const todayD = checkZero(now.getDate());
        var date1 = todayY + '-' + todayM + '-' + todayD;
        console.log(date1)
       return date1;
      }
      setConsultantList(companyId)
      {
       for(var i in this.state.clusterCompanys)
           {
           if(Number(companyId) === Number(this.state.clusterCompanys[i].id))
               {
                this.setState({consultants: this.state.clusterCompanys[i].consultants, consultantId: 0})
               }
           }
      } 
    render() {
        return (<div id="calendarViewAction" className="page-popup page-popup-overlay" >
        <div className="card" >
            <div className="card-header bg-info">Calendar Action</div>
            <div className="card-body" >
                    <div id="cancelAppointmentAction" className="page-popup page-popup-overlay" >
                        <div className="card" >
                            <div className="card-header bg-info">Cancel Action</div>
                            <div className="card-body" >
                            <p>Are you sure you want to cancel  {this.state.entry}?</p> <div className="row">
                                            <div className="col"> <div className="input-group"><span className="input-group-text">Reason</span>
                                                            <textarea className="form-control" value={this.state.reason} onChange={e => { this.setState({ reason: e.target.value }) }} ></textarea></div> </div>       
                                            </div>  
                                <div className="row">
                                <div className="col" align="center" >{ this.state.reason !== '' && this.state.reason.length > 4 &&(<button onClick={() => this.sendCancelAppointment()} className="btn btn-primary">Yes</button>)}</div> 
                                <div className="col" align="center" ><button onClick={() => this.closeCancelPopup()} className="btn btn-primary">No</button></div> 
                                </div>
                            </div>
                        </div>
                    </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card border-primary">
                                    <div className="card-body">
                                        <div className="row">
                                             <div className="col"><div className="input-group"><span className="input-group-text">Start Date</span> <DatePicker className="form-control" selected={new Date(this.state.startDate)} maxDate={new Date(this.state.endDate)} onChange={d => { this.setState({ startDate: this.processDate(d) });} } dateFormat="yyyy-MM-dd" /></div></div>
                                             <div className="col"><div className="input-group"><span className="input-group-text">Company</span><select className="form-control" onChange={(e) => {this.setState({ companyId: e.target.value }); this.setConsultantList(e.target.value) }} value={this.state.companyId}  >
                                                {this.state.clusterCompanys.map((item, index) => (
                                                    <option key={index} value={item.id} >{item.name}</option>
                                                ))}
                                            </select>  </div></div> 
                                            <div className="col"><div className="input-group"><span className="input-group-text">Consultants</span><select className="form-control" onChange={(e) => this.setState({ consultantId: e.target.value })} value={this.state.consultantId} >
                                                <option value="0">All Consultants</option>
                                                {this.state.consultants.map((item, index) => (
                                                    <option key={index} value={item.id} >{item.name}</option>
                                                ))}
                                            </select>  </div></div>
                                            <div className="col">    <input type="button" className="btn btn-success" onClick={() => this.getTimeTableList()} value="Search" /></div>
                                        </div></div></div>
                            </div>
                        </div>
                 { this.state.keys.length > 0 &&(
                 <div className="row">
                 <div className="col">
                 <ul className="list-group list-group-horizontal">
                        {this.state.keys.map((item, index) => (
                        <li className="list-group-item" key={index} >{item.key} <i style={{color: item.value}} class="bi bi-square-fill"></i></li>
                        ))}
                    </ul>
                    </div>
                    </div>
                 )
                 }       
                 <table className="table table-striped" >
                    <thead>
                        <tr>
                            <th>Time</th>
                            {this.state.titles.map((item, index) => (
                            <th key={index}>{item}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                {this.state.timeTables.map((item, index) => (
                        <tr key={index}>
                            <th>{item.time}</th>
                           {item.entry1 === ""?<td className="link" onClick={()=> this.props.openAppointmentByEntry(0, this.state.companyId, this.state.consultantId, item.timeId, this.state.dates[0])}></td> :<td style={{backgroundColor: item.color1}}> <span className="link-calendar" dangerouslySetInnerHTML={{ __html: item.entry1 }}  onClick={()=> this.props.openAppointmentByEntry(item.entryId1, this.state.companyId, item.timeId, this.state.dates[0])} title={item.remarks1}></span> <span className="link-red" onClick={() => this.cancelAppointment(item.entry1, item.entryId1, item.reviewId1)} title="Cancel Appointment"><i className="bi bi-x-square-fill"></i></span> { item.patientId1  > 0 && (<span className="link-white" onClick={() => this.props.openVisitAction(0, item.patientId1, '', 0)} title="Create Visit"><i className="bi bi-file-earmark-fill"></i></span>) }{ (item.patientId1 === 0 && !item.entry1.includes("BLOCK")) && ( <span className="link" onClick={() => this.props.openPatientApp(item.entryId1)} title="Edit Patient"><i class="bi bi-check2-circle"></i></span>)}</td>}
                           {item.entry2 === ""?<td className="link" style={{ backgroundColor: '#cccccc' }} onClick={()=> this.props.openAppointmentByEntry(0, this.state.companyId, this.state.consultantId, item.timeId, this.state.dates[1])}></td> : <td style={{backgroundColor: item.color2}}> <span className="link-calendar" dangerouslySetInnerHTML={{ __html: item.entry2}}  onClick={()=> this.props.openAppointmentByEntry(item.entryId2, this.state.companyId, item.timeId, this.state.dates[1])}  title={item.remarks2}></span> <span className="link-red" onClick={() => this.cancelAppointment(item.entry2, item.entryId2, item.reviewId2)} title="Cancel Appointment"><i className="bi bi-x-square-fill"></i></span> { item.patientId2  > 0 && (<span className="link-white" onClick={() => this.props.openVisitAction(0, item.patientId2, '', 0)} title="Create Visit"><i className="bi bi-file-earmark-fill"></i></span>)}{ (item.patientId2 === 0 && !item.entry2.includes("BLOCK")) &&( <span className="link" onClick={() => this.props.openPatientApp(item.entryId2)} title="Edit Patient"><i class="bi bi-check2-circle"></i></span>)}</td>}
                           {item.entry3 === ""?<td className="link" onClick={()=> this.props.openAppointmentByEntry(0, this.state.companyId, this.state.consultantId, item.timeId, this.state.dates[2])}></td> : <td style={{backgroundColor: item.color3}}> <span className="link-calendar" dangerouslySetInnerHTML={{ __html: item.entry3}}  onClick={()=> this.props.openAppointmentByEntry(item.entryId3, this.state.companyId, item.timeId, this.state.dates[2])} title={item.remarks3}></span> <span className="link-red" onClick={() => this.cancelAppointment(item.entry3, item.entryId3, item.reviewId3)} title="Cancel Appointment"><i className="bi bi-x-square-fill"></i></span> { item.patientId3  > 0 && (<span className="link-white" onClick={() => this.props.openVisitAction(0, item.patientId3, '', 0)} title="Create Visit"><i className="bi bi-file-earmark-fill"></i></span>)}{ (item.patientId3 === 0 && !item.entry3.includes("BLOCK")) &&( <span className="link" onClick={() => this.props.openPatientApp(item.entryId3)} title="Edit Patient"><i class="bi bi-check2-circle"></i></span>)}</td>}
                           {item.entry4 === ""?<td className="link" style={{ backgroundColor: '#cccccc' }} onClick={()=> this.props.openAppointmentByEntry(0, this.state.companyId, this.state.consultantId, item.timeId, this.state.dates[3])}></td> : <td style={{backgroundColor: item.color4}}><span className="link-calendar" dangerouslySetInnerHTML={{ __html: item.entry4}}  onClick={()=> this.props.openAppointmentByEntry(item.entryId4, this.state.companyId, item.timeId, this.state.dates[3])} title={item.remarks4}></span> <span className="link-red" onClick={() => this.cancelAppointment(item.entry4, item.entryId4, item.reviewId4)} title="Cancel Appointment"><i className="bi bi-x-square-fill"></i></span> { item.patientId4  > 0 && (<span className="link-white" onClick={() => this.props.openVisitAction(0, item.patientId4, '',  0)} title="Create Visit"><i className="bi bi-file-earmark-fill"></i></span>)}{ (item.patientId4 === 0 && !item.entry4.includes("BLOCK")) &&( <span className="link" onClick={() => this.props.openPatientApp(item.entryId4)} title="Edit Patient"><i class="bi bi-check2-circle"></i></span>)}</td>}
                           {item.entry5 === ""?<td className="link" onClick={()=> this.props.openAppointmentByEntry(0, this.state.companyId, this.state.consultantId, item.timeId, this.state.dates[4])}></td> : <td style={{backgroundColor: item.color5}}> <span className="link-calendar" dangerouslySetInnerHTML={{ __html: item.entry5}} onClick={()=> this.props.openAppointmentByEntry(item.entryId5, this.state.companyId, item.timeId, this.state.dates[4])} title={item.remarks5}></span> <span className="link-red" onClick={() => this.cancelAppointment(item.entry5, item.entryId5, item.reviewId5)} title="Cancel Appointment"><i className="bi bi-x-square-fill"></i></span>  { item.patientId5  > 0 && (<span className="link-white" onClick={() => this.props.openVisitAction(0, item.patientId5, '', 0)} title="Create Visit"><i className="bi bi-file-earmark-fill"></i></span>)}{ (item.patientId5 === 0 && !item.entry5.includes("BLOCK")) &&( <span className="link" onClick={() => this.props.openPatientApp(item.entryId5)} title="Edit Patient"><i class="bi bi-check2-circle"></i></span>)}</td>}
                           {item.entry6 === ""?<td className="link" style={{ backgroundColor: '#cccccc' }} onClick={()=> this.props.openAppointmentByEntry(0, this.state.companyId, this.state.consultantId, item.timeId, this.state.dates[5])}></td> : <td style={{backgroundColor: item.color6}}><span className="link-calendar" dangerouslySetInnerHTML={{ __html: item.entry6}}  onClick={()=> this.props.openAppointmentByEntry(item.entryId6, this.state.companyId, item.timeId, this.state.dates[5])} title={item.remarks6}></span> <span className="link-red" onClick={() => this.cancelAppointment(item.entry6, item.entryId6, item.reviewId6)} title="Cancel Appointment"><i className="bi bi-x-square-fill"></i></span> { item.patientId6  > 0 && (<span className="link-white" onClick={() => this.props.openVisitAction(0, item.patientId6, '',  0)} title="Create Visit"><i className="bi bi-file-earmark-fill"></i></span>)}{ (item.patientId6 === 0 && !item.entry6.includes("BLOCK")) &&( <span className="link" onClick={() => this.props.openPatientApp(item.entryId6)} title="Edit Patient"><i class="bi bi-check2-circle"></i></span>)}</td>}
                           {item.entry7 === ""?<td className="link" onClick={()=> this.props.openAppointmentByEntry(0, this.state.companyId, this.state.consultantId, item.timeId, this.state.dates[6])}></td> : <td style={{backgroundColor: item.color7}}> <span className="link-calendar" dangerouslySetInnerHTML={{ __html: item.entry7}}  onClick={()=> this.props.openAppointmentByEntry(item.entryId7, this.state.companyId, item.timeId, this.state.dates[6])} title={item.remarks7}></span> <span className="link-red" onClick={() => this.cancelAppointment(item.entry7, item.entryId7, item.reviewId7)} title="Cancel Appointment"><i className="bi bi-x-square-fill"></i></span> { item.patientId7  > 0 && (<span className="link-white" onClick={() => this.props.openVisitAction(0, item.patientId7, '', 0)} title="Create Visit"><i className="bi bi-file-earmark-fill"></i></span>)}{ (item.patientId7 === 0 && !item.entry7.includes("BLOCK")) &&( <span className="link" onClick={() => this.props.openPatientApp(item.entryId7)} title="Edit Patient"><i class="bi bi-check2-circle"></i></span>)}</td>}
                        </tr>
                         ))}
                    </tbody>
                 </table>
                <div className="row">
                <div className="col" align="center" ><button onClick={() => this.closePopup()} className="btn btn-primary">Close</button></div> 
                </div>
            </div>
        </div>
    </div>)

    }
}    